<template>
  <div>
    <!-- 操作選單 -->
    <div class="mb-4">
      <button
        class="tw-btn tw-btn-secondary"
        @click="showModal('advancedSearch')"
      >
        進階搜尋
      </button>
    </div>
    <!-- 帳單列表 -->
    <div class="mb-3">
      <ul v-if="billList.length > 0">
        <li
          class="bill-list mb-3"
          v-for="data in billList"
          :key="data.id"
          :class="{
            'border-success': data.paid && data.packaged && data.shipped,
          }"
        >
          <div class="row">
            <!-- 帳單資訊 -->
            <div class="col p-0">
              <p class="mb-2 fw-bolder text-primary">帳單單號: {{ data.id }}</p>
              <p class="mb-2 text-secondary">
                付款方式: {{ data.paymentType }}
              </p>
              <p class="mb-2 text-secondary">
                <i class="bi bi-chat-dots me-1"></i>
                <span v-if="data.note">{{ data.note }}</span>
                <span v-else>無備註</span>
              </p>
              <p class="text-secondary">
                <i class="bi bi-calendar2-check me-1"></i
                >{{
                  $methods.moment(data.createTime).format("YYYY-MM-DD HH:mm:ss")
                }}
              </p>
            </div>
            <!-- 出貨資訊 -->
            <div class="col">
              <p class="mb-2 fw-bolder text-primary">出貨資訊</p>
              <p class="mb-2">出貨: {{ data.shippingType }}</p>
              <p class="mb-2">收件: {{ data.recipient }}</p>
              <p class="mb-2">電話: {{ data.recipientPhoneNumber }}</p>
              <p>
                地址:
                <span v-if="data.recipientCity">{{ data.recipientCity }}</span>
                <span v-if="data.recipientArea">{{ data.recipientArea }}</span>
                <span v-if="data.receivingAddress">{{
                  data.receivingAddress
                }}</span>
              </p>
            </div>
          </div>
          <button class="tw-btn tw-btn-success" @click="showDetail(data.id)">
            <i class="bi bi-chevron-bar-expand me-1"></i>明細
          </button>
          <div class="d-none" :id="`header${data.id}`">
            <hr />
            <div class="row">
              <!-- 發票資訊 -->
              <div class="col p-0">
                <p class="mb-2 fw-bolder text-primary">發票資訊</p>
                <p class="mb-2">抬頭: {{ data.receiptTitle }}</p>
                <p class="mb-2">統編: {{ data.receiptBusinessAdminNumber }}</p>
                <p class="mb-2">電話: {{ data.receiptPhoneNumber }}</p>
                <p>
                  地址:
                  <span v-if="data.receiptCity">{{ data.receiptCity }}</span>
                  <span v-if="data.receiptArea">{{ data.receiptArea }}</span>
                  <span v-if="data.receiptAddress">{{
                    data.receiptAddress
                  }}</span>
                </p>
              </div>
              <!-- 付款資訊 -->
              <div class="col">
                <p class="mb-2 fw-bolder text-primary">付款資訊</p>
                <div v-if="data.hasPaymentNotification">
                  <p class="mb-2">
                    收款:
                    <span
                      class="text-warning"
                      v-if="data.hasPaymentNotification && !data.paid"
                      >款項確認中</span
                    >
                    <span
                      class="text-danger"
                      v-else-if="!data.hasPaymentNotification && !data.paid"
                      >未收款</span
                    >
                    <span class="text-success" v-else>已收款</span>
                  </p>
                  <p class="mb-2">付款: 匯款</p>
                  <p class="mb-2">
                    金額: {{ data.paymentNotification.money }}元
                  </p>
                  <p class="mb-2">
                    銀行:
                    {{ data.paymentNotification.userPaymentInfo.bankCode }}
                  </p>
                  <p class="mb-2">
                    末五碼:
                    {{ data.paymentNotification.userPaymentInfo.accountNumber }}
                  </p>
                  <p class="mb-2 text-secondary">
                    <i class="bi bi-chat-dots me-1"></i>
                    <span v-if="data.paymentNotification.note">{{
                      data.paymentNotification.note
                    }}</span>
                    <span v-else>無備註</span>
                  </p>
                  <p class="text-secondary">
                    <i class="bi bi-calendar2-check me-1"></i
                    >{{
                      $methods
                        .moment(data.paymentNotification.paymentTime)
                        .format("YYYY-MM-DD HH:mm:ss")
                    }}
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <!-- 總計 -->
            <div class="mb-3">
              <p class="mb-2 badge bg-info bg-gradient" v-if="data.showDetail">
                代客直寄
              </p>
              <p class="mb-2">運費: {{ data.shippingCost }}元</p>
              <p class="mb-2">
                帳單平衡: {{ $methods.numberToFixed(data.balanceTotal) }}元
              </p>
              <p class="mb-2">
                訂單金額:
                <span class="text-danger" v-if="loadingForBodies"
                  >計算中...</span
                >
                <span v-else
                  >{{ $methods.numberToFixed(data.bodiesTotal) }}元</span
                >
              </p>
              <p class="mb-2" v-if="data.discountName">
                結單優惠: {{ $methods.numberToFixed(data.discountAmount) }}
                <p class="badge bg-danger">{{ data.discountName }}</p>
              </p>
              <p class="mb-2 border-bottom border-3 pb-2">
                購物金折抵: {{ data.walletUseAmount }}元
                <span
                  class="text-success"
                  v-if="data.walletUsed && data.walletUseAmount > 0"
                  >(已折)</span
                >
                <span
                  class="text-danger"
                  v-else-if="!data.walletUsed && data.walletUseAmount > 0"
                  >(未折)</span
                >
              </p>
              <p class="text-danger fw-bolder total-money">
                應付金額:
                <span class="test-danger" v-if="loadingForBodies"
                  >計算中...</span
                >
                <span v-else>{{
                  $methods.numberToFixed(data.sholdBePay)
                }}</span>
              </p>
            </div>
            <!-- 單身 -->
            <p class="fw-bolder text-danger" v-if="loadingForBodies">
              請稍後~正在為您取得帳單明細!
            </p>
            <ul class="border-top border-2 pt-2" v-else>
              <li class="row" v-for="body in data.billBodies" :key="body.id">
                <div class="col-2">
                  <Image
                    class="img"
                    :imageHash="body.merchandiseImgHash"
                    size="s"
                    v-if="body.merchandiseImgHash"
                    :alt="body.merchandiseImgHash"
                  ></Image>
                  <img
                    class="img"
                    src="@/assets/other-images/noImg.png"
                    alt=""
                    v-else
                  />
                </div>
                <div class="col-7">
                  <p class="mb-2 fw-bolder text-primary">
                    訂單編號: {{ body.merchOrderId }}
                  </p>
                  <p class="mb-2 fw-bolder">{{ body.merchandiseName }}</p>
                  <p class="mb-2 text-secondary">
                    <i class="bi bi-cart-check me-1"></i>{{ body.style }}
                  </p>
                  <p class="mb-2 text-secondary">
                    <i class="bi bi-chat-dots me-1"></i>
                    {{ body.merchOrder.note }}
                  </p>
                </div>
                <div class="col-3">
                  <p
                    class="fw-bolder mb-2"
                    :class="body.textColorForArrialStatus"
                  >
                    {{ body.arrivalStatus }}
                  </p>
                  <p>{{ body.quantity }}X{{ body.price }}</p>
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ul>
      <p class="alert-danger fw-bolder text-center p-3" v-else>無任何帳單</p>
    </div>
  </div>
  <!-- 進階搜尋的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="advancedSearchModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">進階搜尋</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 時間區間 -->
          <div class="mb-3">
            <!-- 開關 -->
            <div class="form-check form-switch p-0 mb-2">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                id="AS_TimeSwitch"
                v-model="advancedSearchData.timeRange.switch"
              />
              <label class="form-check-label" for="AS_TimeSwitch"
                >時間區間</label
              >
            </div>
            <!-- 時間 -->
            <div class="row mb-3" v-if="advancedSearchData.timeRange.switch">
              <!-- 起始時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_StartTime"
                    placeholder="起始時間"
                    v-model="advancedSearchData.timeRange.startTime"
                  />
                  <label for="AS_StartTime" class="mb-2">起始時間</label>
                </div>
              </div>
              <!-- 結束時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_EndTime"
                    placeholder="結束時間"
                    v-model="advancedSearchData.timeRange.endTime"
                  />
                  <label for="AS_EndTime" class="mb-2">結束時間</label>
                </div>
              </div>
            </div>
          </div>
          <!-- 篩選條件 -->
          <div class="border mb-3 p-3">
            <p class="tw-border-start fw-bolder">篩選條件</p>
            <hr />
            <ul class="list">
              <li
                class="row border-bottom"
                v-for="data in singleSelectsArray"
                :key="data.id"
              >
                <div class="col-3 my-2">{{ data.key }}</div>
                <div class="col-9">
                  <div
                    class="select-radio my-2"
                    v-for="select in data.value"
                    :key="select.id"
                  >
                    <label>
                      <input
                        type="radio"
                        :name="data.id"
                        :value="select.value"
                        v-model="advancedSearchData.singleSelect[data.id]"
                      />
                      <span class="radio-style">{{ select.value }}</span>
                    </label>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="advancedSearch(true)"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// vuex
import { mapState } from "vuex";
// api
import { lineLoginWithoutReg } from '@/methods/API/lineLoginWithoutReg'
import { parseTokenToGetTheParticipant } from '@/methods/API/parseTokenToGetTheParticipant.js'

export default {
  data() {
    return {
      // modal
      advancedSearchModal: {},
      // data
      storeId: 0,
      serverToken: '',
      groupLinkId: '',
      lineUserId: '',
      participantId: 0,
      slaveId: 0,
      billList: [],
      loadingForBodies: false,
      // 進階搜尋
      advancedSearchData: {
        timeRange: {
          switch: true,
          startTime: null,
          endTime: null
        },
        singleSelect: {
          billStatus: '未完成',
        }
      },
      recordAdvancedSearchData: {},
      // 單選篩選陣列
      singleSelectsArray: [],
    }
  },
  computed: {
    ...mapState('StoreList', {
      ownerStores: state => state.ownerStores,
      customerStores: state => state.customerStores,
      fbStores: state => state.fbStores
    })
  },
  created() {
    this.initialization()
    this.computedD4TimeRange()
    if (this.$route.meta.currentModal === 'line') this.initLineSdk()
    else if (this.$route.meta.currentModal === 'fb') this.advancedSearch() // FB 免註冊頁面已停用
  },
  mounted() {
    this.createModals(['advancedSearchModal'])
  },
  methods: {
    initialization() {
      if (this.$route.meta.currentModal === 'line') {
        this.storeId = this.$route.query.storeId
        this.groupLinkId = this.$route.query.groupLinkId
      } else if (this.$route.meta.currentModal === 'fb') {
        this.serverToken = this.$methods.getCookie('fb_serverToken')
        this.storeId = this.$route.params.storeId
        this.fbStores.data.forEach(item => {
          if (item.storeId == this.storeId) this.participantId = item.id
        })
      }
      // 紀錄進階搜尋單選條件
      this.singleSelectsArray = [
        {
          id: 'billStatus',
          key: '帳單狀態',
          value: [
            {
              id: 'A1',
              value: '未完成'
            },
            {
              id: 'A2',
              value: '已完成'
            },
            {
              id: 'A3',
              value: '全部'
            },
          ]
        },
      ]
      // 多紀錄進階搜尋
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
    },
    // * line 內嵌 (start)
    async initLineSdk() {
      this.$methods.switchLoading('show')
      try {
        await liff.init({ liffId: process.env.VUE_APP_BILL_PAGE_LINE_LIFFID })
        const context = liff.getContext()
        if (context.type !== 'external') this.lineUserId = context.userId;
        console.log(this.lineUserId)
        this.lineLogin()
      } catch (error) {
        console.log(error)
        this.$methods.switchLoading('hide')
        alert('尚未取得 Line 相關資訊')
      }
    },
    lineLogin() {
      const result = lineLoginWithoutReg(this.groupLinkId, this.lineUserId)
      result.then(res => {
        if (res.code === '200') {
          console.log(res)
          this.serverToken = res.data
          this.getParticipantId()
        }
      }).catch(err => {
        console.log(err)
        this.$methods.switchLoading('hide')
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
      })
    },
    // * line 內嵌 (end)
    // 取得 participantId 與 slaveId
    getParticipantId() {
      this.$methods.switchLoading('show')
      const result = parseTokenToGetTheParticipant(this.storeId, this.serverToken)
      result.then(res => {
        console.log(res)
        if (res.code === '200') {
          this.participantId = res.data.id
          this.slaveId = res.data.slaves.length > 0 ? res.data.slaves[0].id : 0
          this.advancedSearch()
        }
      }).catch(err => {
        console.log(err.responseJSON)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // 計算預設時間區間
    computedD4TimeRange() {
      this.advancedSearchData.timeRange.endTime = this.$methods.moment().format('YYYY-MM-DD')
      this.advancedSearchData.timeRange.startTime = this.$methods.moment().subtract(90, 'days').format('YYYY-MM-DD')
    },
    showModal(status) {
      if (status === 'advancedSearch') {
        // 進階搜尋
        this.advancedSearchData = JSON.parse(JSON.stringify(this.recordAdvancedSearchData))
        this.advancedSearchModal.show()
      }
    },
    // 進階搜尋
    advancedSearch(closeModal) {
      this.$methods.switchLoading('show')
      this.loadingForBodies = true
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
      let participantIds = []
      if (this.participantId) participantIds.push(this.participantId)
      if (this.slaveId) participantIds.push(this.slaveId)
      let query = ''
      if (this.$route.meta.currentModal === 'line') {
        // 如果有 participant
        if (participantIds.length > 0) query = {"columns":[{"columnName":"id","siteObjectType":27}],"distinct":true,"group":{"groups":[{"column":{"columnName":"buyerId","siteObjectType":27},"operator":{"operator":"=","type":3,"value":participantIds}},{"column":{"columnName":"parentId","siteObjectType":27},"operator":{"operator":true,"type":4}}],"operator":1},"siteObjectType":27,"sqlCommandType":1}
        // 若沒有就代表這個 parseToken 失效 (通常 parseToken 就算是虛擬顧客也可以有資料，但下面這串 query 先留著，以防萬一)
        else query = {"columns":[{"columnName":"Id","siteObjectType":27},{"columnName":"CreateTime","siteObjectType":27}],"group":{"groups":[{"column":{"columnName":"BuyerId","siteObjectType":27},"operator":{"operator":"=","type":1,"value":{"columns":[{"columnName":"Id","siteObjectType":13}],"group":{"groups":[{"column":{"columnName":"StoreId","siteObjectType":13},"operator":{"operator":"=","type":1,"value":this.storeId}},{"column":{"columnName":"UserType","siteObjectType":13},"operator":{"type":3,"value":{"columns":[{"columnName":"UserType","siteObjectType":40}],"distinct":true,"group":{"column":{"columnName":"AppUserid","siteObjectType":40},"operator":{"operator":"=","type":1,"value":this.lineUserId}},"siteObjectType":40,"sqlCommandType":1}}},{"column":{"columnName":"UserId","siteObjectType":13},"operator":{"type":3,"value":{"columns":[{"columnName":"UserId","siteObjectType":40}],"distinct":true,"group":{"column":{"columnName":"AppUserid","siteObjectType":40},"operator":{"operator":"=","type":1,"value":this.lineUserId}},"siteObjectType":40,"sqlCommandType":1}}}],"operator":1},"siteObjectType":13,"sqlCommandType":1}}},{"column":{"columnName":"parentId","siteObjectType":27},"operator":{"operator":true,"type":4}}],"operator":1},"orders":[{"asc":false,"column":{"columnName":"CreateTime","siteObjectType":27}}],"siteObjectType":27,"sqlCommandType":1}
      }
      else if (this.$route.meta.currentModal === 'fb') query = {"columns":[{"columnName":"id","siteObjectType":27},{"columnName":"CreateTime","siteObjectType":27}],"group":{"groups":[{"column":{"columnName":"buyerId","siteObjectType":27},"operator":{"operator":"=","type":1,"value":this.participantId}},{"column":{"columnName":"parentId","siteObjectType":27},"operator":{"operator":"true","type":4}}],"operator":1},"orders":[{"asc":false,"column":{"columnName":"CreateTime","siteObjectType":27}}],"siteObjectType":27,"sqlCommandType":1}
      // 時間區間
      if (this.advancedSearchData.timeRange.switch) {
        if (this.advancedSearchData.timeRange.startTime) {
          const startTime = this.$methods.convertTimeZone(`${this.advancedSearchData.timeRange.startTime} 00:00:00`) / 1000
          query.group.groups.push({
            column: {
              columnName: 'createTime',
              siteObjectType: 27
            },
            operator: {
              operator: ">=",
              type: 1,
              value: {
                name: `from_unixtime(${startTime})`
              }
            }
          })
        }
        if (this.advancedSearchData.timeRange.endTime) {
          const endTime = this.$methods.convertTimeZone(`${this.advancedSearchData.timeRange.endTime} 23:59:59`) / 1000
          query.group.groups.push({
            column: {
              columnName: 'createTime',
              siteObjectType: 27
            },
            operator: {
              operator: "<=",
              type: 1,
              value: {
                name: `from_unixtime(${endTime})`
              }
            }
          })
        }
      }
      // 帳單狀態
      switch (this.advancedSearchData.singleSelect.billStatus) {
        case '未完成':
          query.group.groups.push({
            groups: [{
                column: {
                  columnName: "paid",
                  siteObjectType: 27
                },
                operator: {
                  operator: "=",
                  type: 1,
                  value: false
                }
              },{
                column: {
                  columnName: "packaged",
                  siteObjectType: 27
                },
                operator: {
                  operator: "=",
                  type: 1,
                  value: false
                }
              },{
                column: {
                  columnName: "shipped",
                  siteObjectType: 27
                },
                operator: {
                  operator: "=",
                  type: 1,
                  value: false
                }
              },
            ],
            operator: 2
          })
          break;
        case '已完成':
          query.group.groups.push({
            groups: [{
                column: {
                  columnName: "paid",
                  siteObjectType: 27
                },
                operator: {
                  operator: "=",
                  type: 1,
                  value: true
                }
              },{
                column: {
                  columnName: "packaged",
                  siteObjectType: 27
                },
                operator: {
                  operator: "=",
                  type: 1,
                  value: true
                }
              },{
                column: {
                  columnName: "shipped",
                  siteObjectType: 27
                },
                operator: {
                  operator: "=",
                  type: 1,
                  value: true
                }
              },
            ],
            operator: 1
          })
          break;
      }
      if (closeModal) this.advancedSearchModal.hide()
      this.getBills(query)
    },
    // 取帳單
    getBills(query) {
      const vm = this
      const getBillsApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          query: query,
          methods: '{getBillBalances,getPaymentNotification{getUserPaymentInfo},getReceiptPostalCodeDetail,getRecipientPostalCodeDetail}'
        }
      ]
      this.billList = []
      $.ajax({
        type: 'POST',
        async: true,
        url: getBillsApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const bills = res.data[0].objects
            vm.sortOutBill(bills)
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    sortOutBill(billList) {
      let headerIds = []
      billList.forEach(item => {
        headerIds.push(item.id)
        // 原資料
        const object = {
          billBalances: item.billBalances,
          balanceTotal: 0,
          billBodies: null,
          bodiesTotal: null,
          sholdBePay: null,
          buyerId: item.buyerId,
          class: item.class,
          createTime: item.createTime,
          creatorId: item.creatorId,
          discountAmount: item.discountAmount,
          discountName: item.discountName,
          discountType: item.discountType,
          hierarchy: item.hierarchy,
          id: item.id,
          is_showWithWallet: false,
          note: item.note,
          packaged: item.packaged,
          paid: item.paid,
          parentId: item.parentId,
          paymentNotification: item.paymentNotification,
          hasPaymentNotification: item.paymentNotification.paymentTime ? true : false,
          paymentType: item.paymentType,
          // 收據
          receiptAddress: item.receiptAddress,
          receiptBusinessAdminNumber: item.receiptBusinessAdminNumber,
          receiptPhoneNumber: item.receiptPhoneNumber,
          receiptPostalCode: item.receiptPostalCode,
          receiptCity: item.receiptPostalCode ? item.receiptPostalCodeDetail.City : '',
          receiptArea: item.receiptPostalCode ? item.receiptPostalCodeDetail.Area : null,
          receiptTitle: item.receiptTitle,
          // 收件資訊
          receivingAddress: item.receivingAddress,
          recipient: item.recipient,
          recipientPhoneNumber: item.recipientPhoneNumber,
          recipientPostalCode: item.recipientPostalCode,
          recipientCity: item.recipientPostalCode ? item.recipientPostalCodeDetail.City : '',
          recipientArea: item.recipientPostalCode ? item.recipientPostalCodeDetail.Area : null,
          shipped: item.shipped,
          shippedTime: item.shippedTime,
          shippingCost: item.shippingCost,
          shippingType: item.shippingType,
          showDetail: item.showDetail,
          storeId: item.storeId,
          walletUseAmount: item.walletUseAmount,
          walletUsed: item.walletUsed
        }
        // 需特別處理
        // 帳單平衡總計
        object.balanceTotal = 0
        item.billBalances.forEach(item => {
          object.balanceTotal += item.money
        })
        this.billList.push(JSON.parse(JSON.stringify(object)))
      })
      this.$methods.switchLoading('hide')
      this.getBillBodies(headerIds)
    },
    // 非同步取得帳單單身
    getBillBodies(headerIds) {
      const vm = this
      const getBillBodiesApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 27,
          ids: headerIds,
          methods: '{getBillBodies{getTopestBillHeader,getMerchOrder{getMerchandise{getMerchandisePictureLinks},getMerchandiseStyles,getBuyer{getUser},getStore}}}' 
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getBillBodiesApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const billList = res.data[0].objects
            vm.setBillBodies(billList)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 將單身塞回去單頭
    setBillBodies(billList) {
      const vm = this
      setTimeout(() => {
        vm.billList.forEach(item => {
          billList.some(hasBodies => {
            if (item.id === hasBodies.id) {
              item.bodiesTotal = 0
              hasBodies.billBodies.forEach(body => {
                // 計算單身總價
                item.bodiesTotal += body.price * body.quantity
                // 取得商品圖片
                body.merchandiseImgHash = null
                body.merchOrder.merchandise.merchandisePictureLinks.some(img => {
                  if (img.front) return body.merchandiseImgHash = img.imageHash
                })
                // 取商品名稱
                body.merchandiseName = body.merchOrder.merchandise.name
                // 組商品款式
                body.style = ''
                body.merchOrder.merchandiseStyles.forEach(style => {
                  body.style += `${style.name} `
                })
                // 訂單來自哪個賣場
                body.storeName = body.merchOrder.store.name
                // 買家
                body.buyerName = body.merchOrder.buyer.user.name
                // 到貨狀況
                body.arrivalStatus = null
                body.textColorForArrialStatus = 'text-danger'
                if (body.merchOrder.allocatedQuantity === 0) {
                  body.arrivalStatus = '未到貨'
                  body.textColorForArrialStatus = 'text-secondary'
                } else if (body.merchOrder.quantity === body.merchOrder.allocatedQuantity) {
                  body.arrivalStatus = '全到貨'
                  body.textColorForArrialStatus = 'text-success'
                } else if (body.quantity === body.merchOrder.quantity && body.merchOrder.allocatedQuantity > 0) {
                  body.arrivalStatus = `到貨: ${body.merchOrder.allocatedQuantity}個`
                  body.textColorForArrialStatus = 'text-warning'
                } else {
                  body.arrivalStatus = '分批結單，無法判斷到貨數量'
                  body.textColorForArrialStatus = 'text-danger'
                }
              })
              // 計算應付金額
              const discountAmount = item.discountAmount ? item.discountAmount : 0
              const shippingCost = item.shippingCost ? item.shippingCost : 0
              let money =  item.walletUseAmount ? item.walletUseAmount : 0
              if (!item.walletUsed) money = 0
              item.sholdBePay = 0
              item.sholdBePay = shippingCost + item.balanceTotal + item.bodiesTotal - discountAmount - money // 有加結單優惠
              item.billBodies = hasBodies.billBodies
              return true
            }
          })
        })
        this.loadingForBodies = false
      }, 1)
    },
    // 展開 / 關閉明細
    showDetail(id) {
      if ($(`#header${id}`).hasClass('d-none')) $(`#header${id}`).removeClass('d-none')
      else $(`#header${id}`).addClass('d-none')
    },
  },
}
</script>